
//import lottie from 'lottie-web/build/player/lottie_svg.min.js'
// import lottie from 'lottie-web/build/player/lottie_light.min.js'


function classToggle() {
    const menu = document.getElementById('mobile-menu')

    menu.classList.toggle('hide');
    menu.classList.toggle('show');

    console.log(menu);

}
document.querySelector('.hamburger a').addEventListener('click', classToggle);
document.querySelector('.menu-close').addEventListener('click', classToggle);
