import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


const topbar = document.getElementById('topbar');

ScrollTrigger.create({
  trigger: ".hero-banner",
  start: "top+=20 top",
  //markers:true,
  onEnter:() => topbar.classList.add("active"),
  onLeaveBack:() => topbar.classList.remove("active"),
});


console.log('--topbar')
