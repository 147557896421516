

console.log('-- App.js --')



function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}


docReady(function() {
  console.log('Doc ready')

  require('./swiper/swiper.js');
  require('./lazyload/lazysizes.js');
  require('./gsap/topbar.js');
  require('./gsap/fadein.js');
  require('./anim.js');



});
