// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

// init Swiper:
var swiper = new Swiper(".artwork-swiper", {
    slidesPerView: 1,
    centeredSlides: false,
    spaceBetween: 40,
    loop:false,
    centerInsufficientSlides:true,
    // pagination: {
    //   el: ".swiper-pagination",
    //   clickable: true,
    //   type: "fraction",
    // },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        940: {
          slidesPerView: 'auto',
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 'auto',
          spaceBetween: 40,
        },
      },
  });
